// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDLnD1AMBoaakcWBsl_cTuHzHi0EEDlJRA",
    authDomain: "flaggstangen-chat.firebaseapp.com",
    projectId: "flaggstangen-chat",
    storageBucket: "flaggstangen-chat.appspot.com",
    messagingSenderId: "450337324191",
    appId: "1:450337324191:web:6fd99ef67f611fb49f76aa",
    measurementId: "G-R3P41TRMH7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);