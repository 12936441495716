import React from "react";
const Welcome = () => {

  return (
    <main className="welcome">
      <h2>Velkommen til Fontenens og Flaggstangens private chat</h2>
      <img src="/norgesverige.jpg" alt="Norge og Sverige" width="60%" height="40%" />
    </main>
  );
};

export default Welcome;
