import React from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from "dayjs";

dayjs.locale('no');
dayjs.extend(relativeTime);
const Message = ({ message }) => {
    const [user] = useAuthState(auth);
    const nameParts = message.name.split(" ");
    const createdAt = message.createdAt;
    let createdAtDate;
    if(createdAt != null) {
        console.log(createdAt);
        createdAtDate = dayjs(createdAt.toDate()).fromNow(true);
        console.log(createdAtDate);
    }
    return (
        <div
            className={`chat-bubble ${message.uid === user.uid ? "right" : ""}`}>
            <img
                className="chat-bubble__left"
                src={message.avatar}
                alt="user avatar"
            />
            <div className="chat-bubble.right">
                <div className="container">
                    <span className="user-name">{nameParts[0]}</span>
                    <span className="message-time">{createdAtDate}</span>
                </div>
                <p className="user-message">{message.text}</p>
            </div>
        </div>
    );
};
export default Message;